
/******************
LANGUAGES
*******************/


body:lang(en) {

	:lang(hu) {
		display:none;
	}
}

body:lang(hu) {

	:lang(en) {
		display:none;
	}
}