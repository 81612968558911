
/********************************
********************************
	
	CUSTOM MIXINS
	
*******************************
*********************************/

/*********************
GRAYSCALE
**********************/

@mixin grayscale( $toggle: "on", $percentage: 1 ) {
    $svg-type: "matrix";
    $svg-value: "0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0";
    $ie-alpha: alpha(#{"opacity="}round( $percentage * 100 ) );

    @if $percentage != 1 {
        $svg-type: "saturate";
        $svg-value: $percentage;
    }

    @if $toggle == "on" {
        filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'#{$svg-type}\' values=\'#{$svg-value}\'/></filter></svg>#grayscale"); // Firefox 10+, Firefox on Android
        filter: gray $ie-alpha; // IE6-9
        -webkit-filter: grayscale( round( $percentage * 100% ) ); // Chrome 19+, Safari 6+, Safari 6+ iOS
        filter: grayscale( round( $percentage * 100% ) ); // Catch-all
    }

    @if $toggle == "off" {
        @if $svg-type == "saturate" {
            filter: none;
        } @else {
            filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
        }
        -webkit-filter: grayscale(0%);
        filter: grayscale(0);
    }
}

/*********************
VERTICAL ALIGN
*********************/

@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/*********************
BOX SHADOW
*********************/

@mixin box-shadow($top, $left, $blur, $color) {

      -webkit-box-shadow:$top $left $blur $color;
      -moz-box-shadow:$top $left $blur $color;
      box-shadow:$top $left $blur $color;
    }

/*********************
BORDER RADIUS
*********************/

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}