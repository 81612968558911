
/********************************
********************************
	
	ALL CUSTOM STYLES
	
*******************************
*********************************/

.transition * {
transition: all 0.3s ease 0s;
}

body {
scroll-behavior: smooth;
background-color: #d7d9d8;
background-image: url("../images/shattered-dark.png");
background-attachment: fixed;
/* This is mostly intended for prototyping; please download the pattern and re-host for production environments. Thank you! */
}

#name {
@media #{$small-up} { 
	
	font-size: rem-calc(22px);
	
	}
}

/********************
TITLE BAR
*********************/

.title-bar-right {

	a {
		color: $white;
		font-weight: bold;
	}
}

.title-bar-title {
	cursor: pointer;
}

/**********************
HEADER
**********************/

header {
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	transition: all 0.5s ease 0s;
	height: 100vh;
	
	@media #{$small-up} { 
		min-height: 200px;
		background-image: url('../images/header_small.jpg');
	}

	@media #{$medium-up} { 
		min-height: 300px;
		background-image: url('../images/header_medium.jpg');
	}

	@media #{$large-up} { 
		min-height: 600px;
		background-image: url('../images/header_large.jpg');
	}

	@media #{$xlarge-up} {
		background-image: url('../images/header_xlarge.jpg');
	}

	&:hover { //HOVER
		transition: all 0.5s ease 0s;
		
		#header-inner {
			background-color: rgba(0, 0, 0, 0.2);
			transition: all 0.5s ease 0s;
			
			h1 {
				color: $primary-color;
				text-shadow: -1px -1px 2px rgba(255, 255, 255, 1);
				transition: all 0.5s ease 0s;

				@media #{$small-up} { 
					font-size: rem-calc(75px);
				}

				@media #{$medium-up} { 
					font-size: rem-calc(132px);
				}

				@media #{$large-up} { 
					font-size: rem-calc(252px);
				}
			}

			h2 {
				a {
				color: $black;
				//padding: 0 10px;
				//border-left: 2px solid $primary-color;
				//border-right: 2px solid $primary-color;
				//text-shadow: -1px -1px 2px rgba(255, 255, 255, 1);
				transition: all 0.2s ease 0s;
				}
			}
		}
	}

	#header-inner {
		transition: all 0.5s ease 0s;
		height: 100%;

		#header-content {
			@include vertical-align;
		}

		.menu {
			display: table;
			margin: 0 auto;
			background: rgba(255, 255, 255, 0.3);
			@include border-radius(5px);

			li {
			padding: 10px 20px 4px 20px;	
			}
		}

		h1 {
			font-family: 'Josefin Sans', cursive;
			font-weight: 300;
			text-align: center;
			//color: $black;
			color: $primary-color;
			text-shadow: -1px -1px 2px rgba(255, 255, 255, 1);
			transition: all 0.5s ease 0s;
			cursor: default;
			

			@media #{$small-up} { 
				font-size: rem-calc(75px);
			}

			@media #{$medium-up} { 
				font-size: rem-calc(130px);
			}

			@media #{$large-up} { 
				font-size: rem-calc(250px);
			}
		}

		h2 {
			text-align: center;
			//text-shadow: -1px -1px 3px rgba(255, 255, 255, 1);
			cursor: default;

			a {
				color: $black;
				transition: all 0.1s ease 0s;

				&:hover {
					//padding-top: 2px;
					//padding-bottom: 10px;
					//border-top: 2px solid $primary-color;
					border-bottom: 2px solid $primary-color;
					border-left: none;
					border-right: none;
					//color: $primary-color;
					transition: all 0.1s ease 0s;
				
				}
			}
		}
	}
}

/******************
STRIPE
******************/

.stripe {
border-bottom: 20px solid #8b1015;

	&.margin-bottom {
	margin-bottom: 20px;
	}

	&.margin-top {
	margin-top: 20px;
	}
}

/******************
PORTFOLIO
*******************/

#portfolio {

	h4 {
		text-align: center;
		padding-bottom: 20px;
		transition: all 0.2s ease 0s;
		color: $primary-color;
	}

	p {
		text-align: center;
	}

	.column {
		
		margin-bottom: 20px;
		outline: none;
	
	}

	img {
	}



	.p-item {
		position: relative;
		cursor: pointer;
		outline: none;

		.desc {
			@include vertical-align;
			position: absolute;
			left: 0;
			right: 0;
			height: 0%;
			opacity:0;
			transition: all 0.5s ease 0s;
			outline: none;
			
			ul {
				@include vertical-align;
				text-align: center;

				li {
					color: $white;
				}
			}

			.sitelink {
				position: absolute;
				bottom: rem-calc(20);
				right: rem-calc(20);
				@include disable-mouse-outline;
				outline: none;
			}
		}


		&:hover {
			@include box-shadow(0, 0, 15px, $primary-color);

			.desc {
			@include vertical-align;
			position: absolute;
			left: 0;
			right: 0;
			background: rgba(0, 0, 0, 0.8);
			height: 100%;
			opacity:1;
			transition: all 0.5s ease 0s;
			}

			h4 {
				color: $white;
				transition: all 0.5s ease 0s;
			}
		}
	}

}

/**********************
CONTACT
***********************/

#contact-bg {
	background-size: cover;
	background-position: center bottom;
	background-repeat: no-repeat;
	transition: all 0.5s ease 0s;
	margin-bottom: 30px;

	@media #{$small-up} { 
		background-image: url('../images/header_small.jpg');
	}

	@media #{$medium-up} { 
		background-image: url('../images/header_medium.jpg');
	}

	@media #{$large-up} {
		background-image: url('../images/header_large.jpg');
	}

	@media #{$xlarge-up} {
		background-image: url('../images/header_xlarge.jpg');
	}
	
	&:hover {
		transition: all 0.5s ease 0s;
	}

	#contact {
		padding-top: 20px;
		background: rgba(255, 255, 255, 0.9);

		.fa-facebook-square {
		color: #3b5998;
		}

		.fa-linkedin-square {
		color: #0077b5;
		}

		.fa-envelope-square {
		color: $primary-color;
		}

		.fa {
			transition: all 0.1s ease 0s;
			
			&:hover {
				color: initial;
				transition: all 0.3s ease 0s;
			}
		}
	}
}

/********************
FOOTER
*********************/

footer {
	padding-bottom: 30px;
}

/********************
SLICK
*********************/

.slick-prev:before, .slick-next:before {
	font-size: 25px !important;
}

.slick-slide {
	outline: none;
}

/********************
SWIPE ICON
*********************/

#swipe-icon {
	text-align: center;
	@include hide-for(1200px);
	margin-bottom: rem-calc(20);
}

